import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ToasterService } from "../../services/toaster.service";

@Component({
	selector: "app-add-plan",
	templateUrl: "./add-plan.component.html",
	styleUrls: ["./add-plan.component.css"],
})
export class AddPlanComponent implements OnInit {
	name: any;
	planType: string = "MONTHLY";
	description: any;
	price: any;
	trial: number = 0;

	constructor(
		public dialogRef: MatDialogRef<AddPlanComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private toaster: ToasterService
	) {}

	ngOnInit(): void {
		this.name = this.data.rowData.name;
		this.price = this.data.rowData.price;
		this.planType = this.data.rowData.planType;
		this.description = this.data.rowData.description;
		this.trial = this.data.rowData.trialDays ?? 0;
		console.log(this.data);
	}

	close() {
		this.dialogRef.close();
	}

	submit() {
		let payload;
		const parsedPrice = Number(this.price);

		if (isNaN(parsedPrice) || parsedPrice <= 0) {
			this.toaster.warning("Price must be a number greater than 0");
			return;
		}
		if (!this.name) {
			this.toaster.warning("Please enter name");
			return;
		}
		if (!this.price) {
			this.toaster.warning("Please enter price");
			return;
		}
		if (!this.description) {
			this.toaster.warning("Please enter description");
			return;
		}
		if (!this.planType) {
			this.toaster.warning("Please select plan type");
			return;
		}
		if (this.trial < 0) {
			this.toaster.warning(
				"Trial Days must be a number equal or greater than 0"
			);
			return;
		}
		if (!this.isTrialDaysValid()) {
			return;
		}

		if (this.data.type === "addPlan" && !this.data.isEdit) {
			payload = {
				name: this.name,
				price: parsedPrice,
				description: this.description,
				planType: this.planType,
				trialDays: this.trial,
			};
		} else if (this.data.type === "updatePlan" && this.data.isEdit) {
			payload = {
				name: this.name,
				price: parsedPrice,
				description: this.description,
				planType: this.planType,
				trialDays: this.trial ?? 0,
				planId: this.data.rowData.id,
			};
		}

		this.dialogRef.close(payload);
	}

	private isTrialDaysValid(): boolean {
		const maxDays = this.getMaxTrialDays();
		if (this.trial >= maxDays) {
			this.toaster.warning(
				`Trial days must be less than ${maxDays} days for the selected plan type.`
			);
			return false;
		}
		return true;
	}

	private getMaxTrialDays(): number {
		switch (this.planType) {
			case "MONTHLY":
				return 30;
			case "YEARLY":
				return 365;
			case "QUARTERLY":
				return 90;
			default:
				return 0;
		}
	}
}

<h2 mat-dialog-title class="headerColor">{{this.data.type === 'addPlan' ? 'Add Plan' : 'Update Plan'}}</h2>
<div class="icon">
    <img src="../../../../assets/images/close.png" (click)="close()" class="close-icon" />
</div>
<mat-dialog-content>

    <div class="scrollable-container">
        <div>
            <div class="example-full-width my-3">
                <label>Name</label><span class="text-danger">*</span>
                <input class="form-control" type="text" [(ngModel)]="name" name="name" maxlength="50" required>
            </div>
            <div class="example-full-width my-3">
                <label>Price($) </label><span class="text-danger">*</span>
                <input type="number" class="form-control" [(ngModel)]="price" name="price" id="" maxlength="10">
            </div>
            <div class="example-full-width my-3 custom-select-wrapper">
                <label>Plan Type </label><span class="text-danger">*</span>
                <select [(ngModel)]="planType" class="form-control customSelectCss custom-select" id="planType">
                    <option value="" disabled selected>Select--</option>
                    <option value="MONTHLY">Monthly</option>
                    <option value="YEARLY">Yearly</option>
                    <option value="QUARTERLY">Quarterly</option>
                </select>
            </div>

            <div class="example-full-width my-3">
                <label>Description </label><span class="text-danger">*</span>
                <textarea class="form-control" [(ngModel)]="description" name="description" id="" cols="30"
                    maxlength="1000" rows="5"></textarea>
                <div class="text-muted" *ngIf="description">{{ 1000 - description.length }}/1000 </div>
            </div>
            <div class="example-full-width my-3">
                <label>Trial Days </label><span class="text-danger">*</span>
                <input type="number" class="form-control" [(ngModel)]="trial" name="trial" id="">
            </div>
            <!-- <div class="example-full-width my-3">
                <label>Slug</label><span class="text-danger">*</span>
                <input type="text" class="form-control" [(ngModel)]="slug" name="trial" id="">
            </div> -->
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="d-flex justify-content-end m-lg-4 gap-3">
    <button mat-raised-button type="submit" color="primary" (click)="submit()"
        class="btnText">{{data.isEdit ? "Update" : 'Add'}}</button>
    <button mat-raised-button (click)="close()">Close</button>
</mat-dialog-actions>